<template>
  <div class="w-full h-full px-6 flex flex-col" :class="transitionDirection">
    <LogoHeader
      v-if="showLogoHeader"
      :showLogo="false"
      :showBackButton="showBackButton"
    >
      <div class="flex flex-col">
        <h3 class="capitalize mx-auto mb-4">
          {{ $t("message.password-reset") }}
        </h3>
        <Logo
          class="m-auto"
          color="pink"
          @click="$router.push({ name: 'HomeIn' })"
        />
      </div>
    </LogoHeader>
    <template v-if="step == 'get_email'">
      <PasswordEmail
        @goBack="goBack('email')"
        :email="email"
        :loading="loading"
        @emailSubmited="requestCode"
      />
    </template>
    <template v-if="step == 'ask_code'">
      <PasswordCode
        @goBack="goBack('code')"
        :code="code"
        :email="email"
        @codeProvided="codeProvided"
      />
    </template>
    <template v-if="step == 'ask_password'">
      <PasswordNew
        :showBackButton="false"
        @confirmedPassword="resetPassword"
        :loading="loading"
        :title="$t('message.create-a-new-password')"
        :buttonText="$t('message.continue')"
      />
    </template>
    <template v-if="step == 'password_updated'">
      <PasswordUpdated ctaRoute="SignInPage" :ctaText="$t('message.log-in')" />
    </template>
    <template v-if="response">
      <div class="mx-auto text-center w-full text-red my-2">
        {{ response }}
      </div>
    </template>
  </div>
</template>
<script>
import { Auth } from "@aws-amplify/auth";
import PasswordCode from "@/components/shared/PasswordCode.vue";
import PasswordNew from "@/components/shared/PasswordNew.vue";
import PasswordEmail from "@/components/shared/PasswordEmail.vue";
import PasswordUpdated from "../components/shared/PasswordUpdated.vue";
import LogoHeader from "../components/shared/LogoHeader.vue";
import Logo from "@/components/Logo.vue";

export default {
  components: {
    PasswordCode,
    PasswordNew,
    PasswordEmail,
    PasswordUpdated,
    LogoHeader,
    Logo
  },
  name: "RecoverPassword",
  data() {
    return {
      email: "",
      code: "",
      response: null,
      step: "",
      isEmailLink: false,
      loading: false,
      transitionDirection: "right"
    };
  },
  computed: {
    userIsLogged() {
      return this.$store.getters["auth/isLogged"];
    },
    showLogoHeader() {
      return this.$route.meta.showLogoHeader ?? true;
    },
    showBackButton() {
      return this.$route.meta.showLogoHeader ?? true;
    }
  },
  mounted() {
    if (this.$route.name == "RecoverPasswordConfirm") {
      if (this.$route.query["confirm_code"] && this.$route.query["email"]) {
        this.email = this.$route.query["email"];
        this.code = this.$route.query["confirm_code"];
        this.step = "ask_password";
        this.isEmailLink = true;
      } else {
        this.response = "Error on link";
      }
    } else {
      this.step = "get_email";
    }
  },
  watch: {
    step() {
      this.response = null;
    }
  },
  methods: {
    goBack(from) {
      this.transitionDirection = "left";
      if (this.isEmailLink) {
        this.$router.push({ name: "HomeIn" });
      } else {
        switch (from) {
          case "password":
            this.step = "ask_code";
            break;
          case "code":
            this.step = "get_email";
            break;
          case "email":
            this.$router.push({ name: "SignInPage" });
            break;
        }
      }
    },
    requestCode(email) {
      this.loading = true;
      this.email = email;
      Auth.forgotPassword(this.email.toLowerCase())
        .then(() => {
          this.transitionDirection = "right";
          this.step = "ask_code";
          this.loading = false;
        })
        .catch(err => {
          console.log(err);
          this.response = "Error on request";
          this.loading = false;
        });
    },
    codeProvided(code) {
      this.transitionDirection = "right";
      this.code = code;
      this.step = "ask_password";
    },
    resetPassword(password) {
      this.loading = true;
      this.response = null;
      Auth.forgotPasswordSubmit(this.email.toLowerCase(), this.code, password)
        .then(() => {
          this.transitionDirection = "right";
          this.step = "password_updated";
          this.email = "";
          this.code = "";
          this.loading = false;
        })
        .catch(err => {
          console.log(err);
          this.loading = false;
          this.response = this.$t("message." + err.code);
        });
    }
  }
};
</script>
<style lang="postcss" scoped>
/* Enter and leave animations can use different */

/* durations and timing functions.              */
.right {
  .slide-fade-enter-active {
    transition: all 0.2s ease;
  }

  .slide-fade-leave-active {
    transition: all 0.2s cubic-bezier(1, 0.5, 0.8, 1);
  }

  .slide-fade-enter,
  .slide-fade-leave-to
  /* .slide-fade-leave-active below version 2.1.8 */ {
    transform: translateX(100%);
    opacity: 1;
  }

  .slide-fade-leave-to
  /* .slide-fade-leave-active below version 2.1.8 */ {
    transform: translateX(-100%);
    opacity: 1;
  }
}

.left {
  .slide-fade-enter-active {
    transition: all 0.2s ease;
  }

  .slide-fade-leave-active {
    transition: all 0.2s cubic-bezier(1, 0.5, 0.8, 1);
  }

  .slide-fade-enter,
  .slide-fade-leave-to
  /* .slide-fade-leave-active below version 2.1.8 */ {
    transform: translateX(-100%);
    opacity: 1;
  }

  .slide-fade-leave-to
  /* .slide-fade-leave-active below version 2.1.8 */ {
    transform: translateX(100%);
    opacity: 1;
  }
}
</style>
