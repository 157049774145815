<template>
  <div
    class="w-full h-full flex flex-col flex-grow justify-center items-center w-64"
  >
    <div class="flex-grow flex justify-center items-center flex-col w-64">
      <div
        class="rounded-full overflow-hidden bg-gray-200 flex justify-center items-center w-60 h-60"
      >
        <img src="@/assets/img/icons/lock.svg" />
      </div>
      <h3 class="text-center mt-4">
        {{ $t("message.password-changed") }}
      </h3>
    </div>
    <router-link
      class="btn btn-lg btn-default my-10"
      :to="{
        name: ctaRoute
      }"
    >
      {{ ctaText }}
    </router-link>
  </div>
</template>
<script>
export default {
  name: "PasswordUpdated",
  props: {
    ctaRoute: {
      type: String,
      required: true
    },
    ctaText: {
      type: String,
      required: true
    }
  }
};
</script>
