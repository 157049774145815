<template>
  <div>
    <div class="head flex flex-row justify-center mb-1">
      <h2>{{ $t("message.enter-your-verification-code") }}</h2>
    </div>
    <form @submit.prevent="recoverPassword()">
      <div class="font-body mb-4 mx-auto text-center">
        {{ $t("message.check-your-inbox-verification-code") }}
      </div>
      <InputWrapper
        name="insternalCode"
        :label="$t('message.your-code')"
        type="text"
        :error="insternalCode.error"
        :touched="insternalCode.touched"
        placeholder="Your Verification Code"
        v-model="insternalCode.value"
        @input="validateForm()"
      />
      <button type="submit" class="btn btn-lg btn-default mt-8 my-4 mb-8">
        <span class="mx-auto" v-if="!loading">
          {{ $t("message.verify") }}
        </span>
        <div class="loading w-6 h-6" v-else>
          <Spinner class="sm-button" />
        </div>
      </button>
    </form>
    <div class="flex flex-col">
      <a
        @click="$router.push({ name: 'RequestCode' })"
        class="w-full text-center text-blue-900 uppercase font-button cursor-pointer"
      >
        {{ $t("message.request-a-new-verification-code") }}
      </a>
    </div>
  </div>
</template>
<script>
import InputWrapper from "@/components/form/InputWrapper.vue";
import Spinner from "@/components/shared/Spinner.vue";

export default {
  components: {
    InputWrapper,
    Spinner
  },
  props: {
    email: {
      type: String,
      required: true
    },
    code: {
      type: String,
      required: true
    },
    loading: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      insternalCode: {
        touched: false,
        error: false,
        hint: "",
        value: this.code
      },
      response: null
    };
  },
  methods: {
    validateForm() {
      if (this.insternalCode.value == "") {
        this.insternalCode.error = true;
        this.insternalCode.hint = "code can't be empty";
      } else {
        this.insternalCode.touched = true;
        this.insternalCode.error = false;
        this.insternalCode.hint = "";
      }
    },
    recoverPassword() {
      this.response = null;
      if (this.insternalCode.value != "") {
        this.$emit("codeProvided", this.insternalCode.value);
        console.log("emit message to go to the next step");
      } else {
        this.insternalCode.error = true;
        this.insternalCode.hint = "Please provide a valid code";
        this.response = "Please provide a valid code";
      }
    }
  }
};
</script>
>
