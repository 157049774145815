<template>
  <div>
    <div
      v-if="showBackButton || title"
      class="head flex flex-row mb-8 relative w-full"
    >
      <BackButton
        v-if="showBackButton"
        type="dark"
        @click="$emit('goBack')"
        class="absolute left-4"
      />
      <h3 v-if="title" class="capitalize w-full text-center block">
        {{ title }}
      </h3>
    </div>
    <form v-on:submit.prevent="createPassword()">
      <InputWrapper
        v-if="isChangePassword"
        class="mb-8"
        name="oldPassword"
        :label="$t('message.enter-your-old-password')"
        type="password"
        :error="hasError('oldPassword')"
        :touched="touched('oldPassword')"
        :hint="hint('oldPassword')"
        v-model="oldPassword"
        @input="validateForm(), changed('oldPassword')"
        @blur="changed('oldPassword')"
      />
      <InputWrapper
        class="mb-8"
        name="password"
        :label="newPasswordLabel"
        type="password"
        :error="hasError('password')"
        :touched="touched('password')"
        :hint="hint('password')"
        v-model="password"
        @input="validateForm(), changed('password')"
        @blur="changed('password')"
      />
      <InputWrapper
        name="repeatPassword"
        :label="repeatNewPasswordLabel"
        type="password"
        :error="hasError('repeatPassword')"
        :touched="touched('repeatPassword')"
        :hint="hint('repeatPassword')"
        v-model="repeatPassword"
        @input="validateForm(), changed('repeatPassword')"
        @blur="changed('repeatPassword')"
      />
      <PasswordRules class="mt-4" />
      <button type="submit" class="btn btn-lg btn-default mt-8 mb-8 mx-auto">
        <span v-if="!loading">
          {{ buttonText }}
        </span>
        <div class="loading w-6 h-6" v-else>
          <Spinner class="sm-button" />
        </div>
      </button>
    </form>
  </div>
</template>
<script>
import InputWrapper from "@/components/form/InputWrapper.vue";
import BackButton from "@/components/shared/BackButton.vue";
import Spinner from "@/components/shared/Spinner.vue";
import PasswordRules from "@/components/shared/PasswordRules.vue";
import { customPasswordValidator } from "@/utils/basicHelpers.js";

var formValidatorMixin = {};
export default {
  components: {
    InputWrapper,
    BackButton,
    Spinner,
    PasswordRules
  },
  mixin: [formValidatorMixin],
  props: {
    loading: {
      type: Boolean,
      required: false,
      default: false
    },
    isChangePassword: {
      type: Boolean,
      required: false,
      default: false
    },
    showBackButton: {
      type: Boolean,
      required: false,
      default: true
    },
    title: {
      type: String,
      required: false
    },
    buttonText: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      oldPassword: "",
      password: "",
      repeatPassword: "",
      response: null,
      validation: {},
      showAllErrors: false
    };
  },
  computed: {
    touched() {
      return key => {
        return this.validation[key]?.touched == true;
      };
    },
    hasError() {
      return key => {
        return (
          (this.validation[key]?.error == true &&
            (this.showAllErrors == true ||
              this.validation[key]?.touched == true)) ||
          false
        );
      };
    },
    hint() {
      return key => {
        return this.validation[key]?.touched || this.showAllErrors
          ? this.validation[key]?.hint || ""
          : "";
      };
    },
    newPasswordLabel() {
      if (this.isChangePassword) {
        return this.$t("message.enter-your-new-password");
      } else {
        return this.$t("message.new-password");
      }
    },
    repeatNewPasswordLabel() {
      if (this.isChangePassword) {
        return this.$t("message.re-enter-your-new-password");
      } else {
        return this.$t("message.repeat-new-password");
      }
    }
  },
  methods: {
    changed(key) {
      if (!this.validation[key]) {
        this.validation[key] = {
          touched: true
        };
      } else {
        this.validation[key].touched = true;
      }
    },
    addError(key, message) {
      if (this.validation[key]) {
        this.validation[key].error = true;
        this.validation[key].hint = message;
      } else {
        this.validation[key] = {
          hint: message,
          error: true
        };
      }
    },
    clearError(key) {
      if (this.validation[key]) {
        this.validation[key].error = false;
        this.validation[key].hint = "";
      }
    },
    resetForm() {
      this.validation = {};
    },
    validateForm() {
      let valid = true;
      if (this.password == "") {
        this.addError("password", "The password can't be empty");
        valid = false;
      } else {
        this.clearError("password");
      }
      if (this.repeatPassword == "") {
        this.addError("repeatPassword", "The password can't be empty");
        valid = false;
      } else {
        this.clearError("repeatPassword");
      }
      if (this.isChangePassword && this.oldPassword == "") {
        this.addError("oldPassword", "The password can't be empty");
        valid = false;
      } else {
        this.clearError("oldPassword");
      }
      if (this.repeatPassword != this.password) {
        this.addError("repeatPassword", "Password does not match");
        valid = false;
      }
      if (!customPasswordValidator(this.password)) {
        this.addError("password", "Password is not strong enough");
        valid = false;
      }
      return valid;
    },
    createPassword() {
      this.showAllErrors = true;
      const valid = this.validateForm();
      this.response = null;
      if (valid) {
        if (this.isChangePassword) {
          this.$emit("confirmedPassword", this.oldPassword, this.password);
        } else {
          this.$emit("confirmedPassword", this.password);
        }
      } else {
        this.response = "Please verify the form";
      }
    }
  }
};
</script>
>
