<template>
  <div>
    <div class="head flex flex-row justify-center mb-8">
      <h2>{{ $t("message.forgot-your-password-question") }}</h2>
    </div>
    <form @submit.prevent="recoverPassword()">
      <InputWrapper
        name="internalEmail"
        :label="$t('message.enter-your-email-address')"
        type="text"
        :error="internalEmail.error"
        :touched="internalEmail.touched"
        placeholder="Email Address"
        :hint="
          internalEmail.hint || $t('message.email-address-used-to-sign-up')
        "
        v-model="internalEmail.value"
        @blur="validateForm()"
      />
      <button type="submit" class="btn btn-lg btn-default mt-8 mb-8">
        <span class="mx-auto" v-if="!loading">
          {{ $t("message.request-code") }}
        </span>
        <div class="loading w-6 h-6" v-else>
          <Spinner class="sm-button" />
        </div>
      </button>
    </form>
    <div class="flex flex-col">
      <router-link
        :to="{ name: 'SignInPage' }"
        class="w-max mx-auto text-center text-blue-900 uppercase font-button cursor-pointer"
      >
        {{ $t("message.member-log-in") }}
      </router-link>
      <router-link
        :to="{ name: 'SignUpEmailForm' }"
        class="w-max mx-auto text-center mt-6 text-blue-900 uppercase font-button cursor-pointer"
      >
        {{ $t("message.sign-up-for-account") }}
      </router-link>
    </div>
  </div>
</template>
<script>
import InputWrapper from "@/components/form/InputWrapper.vue";
import Spinner from "@/components/shared/Spinner.vue";
import { isValidEmailFormat, isValidEmailLength } from "@/utils/basicHelpers";

export default {
  components: {
    InputWrapper,
    Spinner
  },
  props: {
    email: {
      type: String,
      required: true
    },
    loading: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      internalEmail: {
        touched: false,
        error: false,
        hint: "",
        value: this.email,
        next: null
      },
      response: null
    };
  },
  methods: {
    validateForm() {
      let valid = true;
      if (this.internalEmail.value == "") {
        this.addError("internalEmail", "The email can't be empty");
        valid = false;
      } else if (!isValidEmailLength(this.internalEmail.value)) {
        this.addError("internalEmail", "Email is too long");
        valid = false;
      } else if (!isValidEmailFormat(this.internalEmail.value)) {
        this.addError("internalEmail", "Invalid email format");
        valid = false;
      } else {
        this.clearError("internalEmail");
      }
      return valid;
    },
    recoverPassword() {
      this.response = null;
      if (this.validateForm()) {
        this.$emit("emailSubmited", this.internalEmail.value);
      }
    },
    addError(field, message) {
      this[field].error = true;
      this[field].hint = message;
    },
    clearError(field) {
      this[field].touched = true;
      this[field].error = false;
      this[field].hint = null;
    }
  }
};
</script>
>
